import { ReactNode, useState, type SyntheticEvent } from "react";
import { twMerge } from "tailwind-merge";
import { format, isToday, isYesterday, isThisYear, isWithinInterval, subDays, startOfDay } from "date-fns";
import { useSignal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime";
import type { SignalType } from "@/lib/signals";
export function Card({
  title,
  tools,
  className,
  children
}: {
  title?: string;
  tools?: ReactNode;
  className?: string;
  children: ReactNode;
}) {
  return <div className={twMerge("bg-white rounded-lg shadow-md mb-2 border border-gray-200 group", className)} data-sentry-component="Card" data-sentry-source-file="display.tsx">
      {tools && <div className="float-right pr-2 pl-4 opacity-10 hover:!opacity-100 group-hover:opacity-25">
          {tools}
        </div>}
      {title && <h2 className="text-lg font-semibold bg-gray-500 text-white inline-block rounded-tl-lg rounded-br-lg px-2">
          {title}
        </h2>}
      <div className="p-4">{children}</div>
    </div>;
}
export function Field({
  label,
  children
}: {
  label: string;
  children: React.ReactNode;
}) {
  return <div data-sentry-component="Field" data-sentry-source-file="display.tsx">
      <div className="font-semibold text-xs">{label}</div>
      <div className="pl-2">{children}</div>
    </div>;
}
export function Time({
  timestamp,
  className
}: {
  timestamp: number;
  className?: string;
}) {
  const date = new Date(timestamp);
  return <div className={className} data-sentry-component="Time" data-sentry-source-file="display.tsx">
      {date.toLocaleString(undefined, {
      dateStyle: "medium",
      timeStyle: "medium"
    })}
    </div>;
}
export function TimeLengthFrom({
  timestamp,
  exact,
  className
}: {
  timestamp: number;
  exact?: boolean;
  className?: string;
}) {
  const [length, setLength] = useState(0);
  if (!timestamp) {
    return null;
  }
  setInterval(() => setLength(Date.now() - timestamp), 1000);
  return <TimeLength milliseconds={length} exact={exact} className={className} data-sentry-element="TimeLength" data-sentry-component="TimeLengthFrom" data-sentry-source-file="display.tsx" />;
}
export function TimeLength({
  milliseconds,
  exact,
  className
}: {
  milliseconds: number;
  exact?: boolean;
  className?: string;
}) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (milliseconds === 0) {
    return <div className={className}>0s</div>;
  } else if (!milliseconds) {
    return <div className={className}>?s</div>;
  }
  const parts: string[] = [];
  if (days > 0) {
    parts.push(`${days}d`);
  }
  if (hours > 0) {
    parts.push(`${hours % 24}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes % 60}m`);
  }
  if (seconds > 0 && !exact) {
    parts.push(`${seconds % 60}s`);
  }
  if (seconds > 0 && exact) {
    parts.push(`${seconds % 60}.${milliseconds % 1000}s`);
  }
  if (milliseconds < 1000) {
    parts.push(`${milliseconds}ms`);
  }
  return <div className={className} data-sentry-component="TimeLength" data-sentry-source-file="display.tsx">{parts.join(" ")}</div>;
}
export function ByteSize({
  bytes
}: {
  bytes: number;
}) {
  const kb = bytes / 1024;
  const mb = kb / 1024;
  const gb = mb / 1024;
  if (gb >= 1) {
    return <span>{gb.toFixed(1)}gb</span>;
  } else if (mb >= 10) {
    return <span>{Math.round(mb)}mb</span>;
  } else if (mb >= 1) {
    return <span>{mb.toFixed(1)}mb</span>;
  } else if (kb >= 10) {
    return <span>{Math.round(kb)}kb</span>;
  } else if (kb >= 0.5) {
    return <span>{kb.toFixed(1)}kb</span>;
  } else {
    return <span>{bytes}bytes</span>;
  }
}
export function HeaderBreak({
  title,
  className,
  children
}: {
  title: string;
  className?: string;
  children?: React.ReactNode;
}) {
  return <div className={twMerge("border-t-2 border-gray-600 mt-2", className)} data-sentry-component="HeaderBreak" data-sentry-source-file="display.tsx">
      <h2 className="rounded-br-2xl rounded-bl bg-gray-600 text-white inline-block pl-2 pr-4 font-semibold">
        {title}
      </h2>
      {children ? <div className="p-2">{children}</div> : null}
    </div>;
}
export function Notification({
  className,
  title,
  children,
  error
}: {
  className?: string;
  title?: string;
  children: ReactNode;
  error?: boolean;
}) {
  return <div className={twMerge("bg-green-100 border rounded border-gray-green-500 p-4 my-4 shadow-xl", error ? "bg-red-100 border-red-500" : "", className)} data-sentry-component="Notification" data-sentry-source-file="display.tsx">
      {title && <h1 className="text-lg font-bold">{title}</h1>}
      {children}
    </div>;
}
export function Pre({
  className,
  children
}: {
  className?: string;
  children: ReactNode;
}) {
  return <pre className={twMerge("font-sans whitespace-pre-wrap", className)} data-sentry-component="Pre" data-sentry-source-file="display.tsx">
      {children}
    </pre>;
}
type PastDateViewProps = {
  date: string | number | Date;
  className?: string;
};
export function PastDateView({
  date,
  className
}: PastDateViewProps) {
  // T00:00:00 makes it parse properly
  let parsedDate: Date;
  if (typeof date === "string") {
    parsedDate = new Date(date + "T00:00:00");
  } else if (typeof date === "number") {
    parsedDate = new Date(date);
  } else {
    parsedDate = date;
  }
  const today = startOfDay(new Date());
  const sixDaysAgo = subDays(today, 6);
  let formattedDate: string;
  if (isToday(parsedDate)) {
    formattedDate = "Today";
  } else if (isYesterday(parsedDate)) {
    formattedDate = "Yesterday";
  } else if (parsedDate > today) {
    formattedDate = format(parsedDate, "EEE, MMM d, yyyy");
  } else if (isWithinInterval(parsedDate, {
    start: sixDaysAgo,
    end: today
  })) {
    formattedDate = format(parsedDate, "EEE");
  } else if (isThisYear(parsedDate)) {
    formattedDate = format(parsedDate, "EEE, MMM d");
  } else {
    formattedDate = format(parsedDate, "EEE, MMM d, yyyy");
  }
  return <span className={className} data-sentry-component="PastDateView" data-sentry-source-file="display.tsx">{formattedDate}</span>;
}
export function P({
  children
}: {
  children: ReactNode;
}) {
  return <div className="mb-4" data-sentry-component="P" data-sentry-source-file="display.tsx">{children}</div>;
}
export function H1({
  children,
  className
}: {
  children: ReactNode;
  className?: string;
}) {
  return <h1 className={twMerge("text-2xl font-bold mb-4", className)} data-sentry-component="H1" data-sentry-source-file="display.tsx">
      {children}
    </h1>;
}
export function H2({
  children,
  className
}: {
  children: ReactNode;
  className?: string;
}) {
  return <h2 className={twMerge("text-md font-bold mb-2", className)} data-sentry-component="H2" data-sentry-source-file="display.tsx">{children}</h2>;
}
export function FieldSet({
  title,
  className,
  children
}: {
  title: ReactNode;
  className?: string;
  children?: ReactNode;
}) {
  return <fieldset className={twMerge("border border-gray-600 mt-2 rounded p-2", className)} data-sentry-component="FieldSet" data-sentry-source-file="display.tsx">
      <legend className="inline-block px-2 ml-2 font-semibold text-xs">
        {title}
      </legend>
      {children}
    </fieldset>;
}
export function FoldableFieldSet({
  title,
  foldedSignal,
  className,
  children
}: {
  title: ReactNode;
  foldedSignal?: SignalType<boolean>;
  className?: string;
  children: ReactNode;
}) {
  useSignals();
  const backupSignal = useSignal(false);
  // eslint-disable-next-line preact-signals/no-implicit-boolean-signal
  const isOpenSignal = foldedSignal ?? backupSignal;
  return <FieldSet title={<span className="cursor-pointer" onClick={() => {
    isOpenSignal.value = !isOpenSignal.value;
  }}>
          {title} {isOpenSignal.value ? "▼" : "▶"}
        </span>} className={className} data-sentry-element="FieldSet" data-sentry-component="FoldableFieldSet" data-sentry-source-file="display.tsx">
      {isOpenSignal.value ? children : <button className="hover:text-blue-700 text-gray-600" onClick={() => {
      isOpenSignal.value = !isOpenSignal.value;
    }}>
          ... hidden ...
        </button>}
    </FieldSet>;
}
export function Details({
  summary,
  children,
  className,
  lazy
}: {
  summary: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  lazy?: boolean;
}) {
  useSignals();
  const isOpen = useSignal(false);
  function handleToggle(e: SyntheticEvent<HTMLDetailsElement, Event>) {
    const details = e.currentTarget as HTMLDetailsElement;
    isOpen.value = details.open;
  }
  return <details className={twMerge("group", className)} onToggle={handleToggle} data-sentry-component="Details" data-sentry-source-file="display.tsx">
      <summary className="cursor-pointer text-sm font-medium py-2 hover:bg-gray-50 transition-colors duration-150 flex items-center">
        <span className="inline-block w-4 h-4 mr-2 text-gray-500 transition-transform duration-200 group-open:rotate-90">
          ▶
        </span>
        <span className="hover:underline">{summary}</span>
      </summary>
      <div className="pl-6 pt-2 pb-4 border-l border-b border-gray-500 ml-2">
        {(!lazy || isOpen.value) && children}
      </div>
    </details>;
}