export function isIOS() {
  return /iP(ad|hone|od)/.test(navigator.userAgent);
}

export class AudioSequence {
  audioQueue: HTMLAudioElement[];
  isPlaying: boolean;
  separatorInstance?: HTMLAudioElement;

  constructor(public separatorUrl?: string) {
    this.audioQueue = [];
    this.isPlaying = false;
    if (separatorUrl && typeof window !== "undefined") {
      this.separatorInstance = new Audio(separatorUrl);
    }
  }

  async play(audioUrls: string[]) {
    const audioInstances = audioUrls.map((url) => new Audio(url));
    if (isIOS()) {
      // Safari is such a pain in the butt
      audioInstances[0].play();
      return;
    }
    this.audioQueue.push(...audioInstances);
    if (!this.isPlaying) {
      this.isPlaying = true;
      await this.playNext();
    }
  }

  private async playNext() {
    if (this.audioQueue.length === 0) {
      this.isPlaying = false;
      return;
    }

    const audioInstance = this.audioQueue.shift();
    if (audioInstance) {
      await this.playAudio(audioInstance);

      if (this.separatorInstance && this.audioQueue.length > 0) {
        await this.playAudio(this.separatorInstance);
      }

      await this.playNext();
    }
  }

  private async playAudio(audioInstance: HTMLAudioElement): Promise<void> {
    return new Promise((resolve) => {
      audioInstance.play();
      audioInstance.addEventListener(
        "ended",
        () => {
          audioInstance.remove(); // Remove the audio element from memory
          resolve();
        },
        { once: true }
      );
    });
  }

  cleanup() {
    // Clean up any remaining audio instances
    this.audioQueue.forEach((audio) => {
      audio.pause();
      audio.remove();
    });
    this.audioQueue = [];
    this.isPlaying = false;
    if (this.separatorInstance) {
      this.separatorInstance.remove();
      this.separatorInstance = undefined;
    }
  }
}
