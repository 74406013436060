import { formatDateTime, localTimezone } from "../dateformat";
import { LogItem } from "../server/captureconsole";

export type FunctionResultType = {
  error?: string;
  errorInfo?: any;
  stack?: string;
  result?: any;
  logs: LogItem[];
  time: number;
};

const RETRY_FETCH = 3;

async function retryFetch(
  url: string,
  options: RequestInit,
  retries = RETRY_FETCH
): Promise<Response> {
  try {
    return await fetch(url, options);
  } catch (error) {
    if (retries === 0 || error instanceof TypeError === false) {
      throw error;
    }
    // Wait 1 second before retrying (you could implement exponential backoff here)
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return retryFetch(url, options, retries - 1);
  }
}

export function createFuncsProxy<
  T extends Record<string, (...args: any) => Promise<any>>,
>(moduleName: string): T & { cached: T } {
  const proxy = new Proxy(
    {},
    {
      get(_, methodName: string) {
        if (methodName === "then") {
          return undefined;
        }
        if (methodName === "cached") {
          return cached;
        }
        return async (...args: any[]) => {
          const response = await retryFetch(
            `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/proxy?module=${encodeURIComponent(moduleName)}&function=${encodeURIComponent(methodName)}`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                args,
                timezone: localTimezone(),
                localDateTime: formatDateTime(new Date()),
              }),
            }
          );

          if (!response.ok) {
            const json: FunctionResultType = await response.json();
            const err = json?.error ? `: ${json.error}` : "";
            console.error(
              `CALL(error) ${moduleName}.${methodName}(`,
              ...args,
              `)\n->`,
              err
            );
            if (json?.errorInfo) {
              console.warn("  Additional info:", json.errorInfo);
            }
            for (const log of json?.logs || []) {
              (console as any)[log.level]("server:", ...log.args);
            }
            throw new Error(`Server error: ${response.statusText}${err}`);
          }

          const result = (await response.json()) as FunctionResultType;
          console[result.error ? "error" : "info"](
            `CALL ${moduleName}.${methodName}(`,
            ...args,
            `)\n->`,
            result.result || result.error,
            `time: ${result.time}ms`
          );
          if (result.error) {
            console.error(
              `  Error: ${result.error}${result.stack ? "\n" + result.stack : ""}`
            );
            if (result.errorInfo) {
              console.warn("  Additional info:", result.errorInfo);
            }
            for (const log of result.logs) {
              (console as any)[log.level]("server:", ...log.args);
            }
            throw new Error(result.error);
          }
          for (const log of result.logs) {
            (console as any)[log.level]("server:", ...log.args);
          }
          return result.result;
        };
      },
    }
  ) as T & { cached: T };
  const cached = cachedProxy(proxy);
  return proxy;
}

function cachedProxy<T>(proxy: T): T {
  const _cache = new Map<string, any>();
  const _inProgress = new Map<string, Promise<any>>();
  return new Proxy(
    {},
    {
      get(_, methodName: string) {
        if (methodName === "then") {
          return undefined;
        }
        return async (...args: any[]) => {
          const cacheKey = JSON.stringify([methodName, args]);
          if (_cache.has(cacheKey)) {
            return _cache.get(cacheKey);
          }
          if (_inProgress.has(cacheKey)) {
            return await _inProgress.get(cacheKey);
          }
          const promise = (proxy as any)[methodName](...args);
          _inProgress.set(cacheKey, promise);
          const result = await promise;
          _cache.set(cacheKey, result);
          _inProgress.delete(cacheKey);
          return result;
        };
      },
    }
  ) as T;
}

export function createStreamProxy<
  T extends Record<string, (...args: any) => AsyncGenerator<any, void, void>>,
>(moduleName: string, ignoreMessage?: (msg: any) => boolean): T {
  const proxy = new Proxy(
    {},
    {
      get(_, methodName: string) {
        if (methodName === "then") {
          return undefined;
        }
        async function* repl(...args: any[]) {
          const stream = fetchStream(
            `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/proxy/sse?module=${encodeURIComponent(moduleName)}&function=${encodeURIComponent(methodName)}`,
            {
              args,
              timezone: localTimezone(),
              localDateTime: formatDateTime(new Date()),
            },
            { moduleName, methodName }
          );

          console.info(
            `CALL/STREAM ${moduleName}.${methodName}(`,
            ...args,
            `)...`
          );
          const start = Date.now();
          let time: number = 0;
          for await (const { event, data } of stream) {
            if (event === "message") {
              if (ignoreMessage && ignoreMessage(data)) {
                console.log(`  ${methodName}/${Date.now() - start}ms ->`, data);
              }
              yield data;
            } else if (event === "log") {
              const RESET = "\x1b[0m"; // Reset to default color
              const GREEN = "\x1b[32m"; // Green text
              (console as any)[data.level](
                `${GREEN}server:${RESET}`,
                ...data.args
              );
            } else if (event === "error") {
              console.error(
                `  ${methodName} error: ${data.error}${data.stack ? "\n" + data.stack : ""}`
              );
              if (data.errorInfo) {
                console.warn("  Additional info:", data.errorInfo);
              }
              throw new Error(data.error);
            } else if (event === "ping") {
              // Do nothing
            } else if (event === "time") {
              time = data;
            }
          }
          if (!time) {
            time = Date.now() - start;
          }
          console.info(
            `... finish CALL/STREAM ${moduleName}.${methodName} in ${time}ms`
          );
        }
        return repl;
      },
    }
  ) as T;
  return proxy;
}

async function* parseSSE(reader: ReadableStreamDefaultReader<Uint8Array>) {
  const decoder = new TextDecoder();
  let buffer = "";
  let eventType = "message"; // Default event type

  while (true) {
    const { value, done } = await reader.read();
    if (done) break;

    buffer += decoder.decode(value, { stream: true });
    const lines = buffer.split("\n");
    buffer = lines.pop() || ""; // Save unfinished lines

    for (const line of lines) {
      if (line.startsWith("event:")) {
        eventType = line.slice(6).trim(); // Set event type
      } else if (line.startsWith("data:")) {
        const data = JSON.parse(line.slice(5).trim());
        yield { event: eventType, data }; // Yield event type & data
        eventType = "message"; // Reset to default event type
      }
    }
  }
}

async function* fetchStream(
  url: string,
  body: any,
  info: { moduleName: string; methodName: string }
) {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const json: FunctionResultType = await response.json();
    const err = json?.error ? `: ${json.error}` : "";
    console.error(
      `CALL(error) ${info.moduleName}.${info.methodName}(`,
      ...((body as any).args || body),
      `)\n->`,
      err
    );
    if (json?.errorInfo) {
      console.warn("  Additional info:", json.errorInfo);
    }
    for (const log of json?.logs || []) {
      (console as any)[log.level]("server:", ...log.args);
    }
    throw new Error(`Server error: ${response.statusText}${err}`);
  }

  if (!response.body) {
    throw new Error("No response body");
  }

  yield* parseSSE(response.body.getReader());
}
