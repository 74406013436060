import { createContext, useContext, ReactNode } from "react";
import type { UserSettingsType } from "../lib/types";
interface SettingsContextType {
  settings: UserSettingsType;
  updateSettings: (newSettings: UserSettingsType) => void;
}
const SettingsContext = createContext<SettingsContextType | undefined>(undefined);
interface SettingsProviderProps {
  children: ReactNode;
  settings: UserSettingsType;
  onUpdateSettings: (newSettings: UserSettingsType) => void;
}
export function SettingsProvider({
  children,
  settings,
  onUpdateSettings
}: SettingsProviderProps) {
  const value = {
    settings,
    updateSettings: onUpdateSettings
  };
  return <SettingsContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="SettingsProvider" data-sentry-source-file="settingsprovider.tsx">
      {children}
    </SettingsContext.Provider>;
}
export function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
}