import chatdb from "@/lib/client/chatdb";
import type { OnboardedSettingNameType } from "@/lib/types";
import { QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import { useTour, type StepType } from "@reactour/tour";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { hashParamsSignal } from "./hashparams";
import { useSettings } from "./settingsprovider";
type StepMatcherType = {
  urlMatch: RegExp;
  urlNotMatch?: RegExp;
  settingName: OnboardedSettingNameType;
  steps: StepType[];
};
export const steps: StepMatcherType[] = [{
  urlMatch: /^\/home\/?$/,
  settingName: "home",
  steps: [{
    selector: "#voice-button",
    content: "Press here to record and send one message or question"
  }, {
    selector: "#type-button",
    content: "Press here to type"
  }, {
    selector: "#locked-voice",
    content: 'Toggle this on to keep the microphone open. You may also say "hold mic" to keep the microphone open and not send a message until you say "send" or "finished"'
  }, {
    selector: "#full-voice",
    content: "Press here to enter driving/full voice mode. The microphone will stay on and all output will be spoken as well as displayed."
  }, {
    selector: "#use-speech",
    content: "Toggle this to turn voice output on and off"
  }, {
    selector: "#add-items",
    content: "Press here to add items to your catalog/inventory"
  }, {
    selector: "#auth-menu",
    content: "Press here to sign in or out, and also to access settings.\n\nSettings lets you change the name and voice of the assistant."
  }, {
    selector: "#settings-button",
    content: "Press here to open a document, catalog, to-do list, or create a new document."
  }]
}, {
  urlMatch: /^\/home\/?\?.*doc=./,
  urlNotMatch: /^\/home\/?\?.*tab=doc/,
  settingName: "homeWithDocument",
  steps: [{
    selector: "#input-bar",
    content: "Welcome to a document chat! Input and chat work just like before"
  }, {
    selector: "#document-tab",
    content: "This shows the document you're currently viewing"
  }, {
    selector: "#chat-tab",
    content: "This lets you chat in the context of this document"
  }, {
    selector: "#clear-document",
    content: "This goes closes the document, returning to the default chat interface"
  }, {
    selector: "#settings-button",
    content: "Press here to open a different document, catalog, to-do list, or create a new document."
  }]
}, {
  urlMatch: /^\/home\/capture\/?/,
  settingName: "capture",
  steps: [{
    selector: "#back-link",
    content: "Press here to go back and view the catalog items"
  }, {
    selector: "#capture-image",
    content: "Press here to turn on the camera, then press again to take a photo of something you want to add to the catalog. Note you can only take photos when the microphone is on!"
  }, {
    selector: "#facing-button",
    content: "Press here to switch front/back facing camera"
  }, {
    selector: "#catalog-selector",
    content: "If you have multiple catalogs you can change the catalog here. (To start out you'll only have one catalog until you make more.)"
  }, {
    selector: "#recording-status",
    content: "This will show items that are added to the catalog and the status of uploading and parsing"
  }, {
    selector: "#microphone-button",
    content: "Press here to start! You can record yourself for a short while or a long while as you collect items. Everything will be parsed only when you click here to stop. Note you can only use the camera while the microphone is on!\n\nInformation isn't saved between recording sessions, but in one session you can say something like \"I'm in the kitchen...\" and the system will know everything you describe after that is in the kitchen."
  }]
}, {
  urlMatch: /^\/home\/?\?.*screen=settings/,
  settingName: "settings",
  steps: [{
    selector: "#new-document",
    content: "Click this to create a new document"
  }, {
    selector: ".doc-type-catalog",
    content: "Click this to open the catalog"
  }, {
    selector: ".doc-type-onboard",
    content: "Click this to open the onboarding"
  }, {
    selector: "#remove-focus",
    content: "Click this to remove the focus"
  }]
}];
export function useOnboarding() {
  const pathname = usePathname();
  const query = useSearchParams();
  const hash = typeof window !== "undefined" ? window.location.hash : "";
  const url = `${pathname}${query.toString() ? `?${query}` : ""}${hash}`;
  const {
    settings,
    updateSettings
  } = useSettings();
  const stepMatcher = steps.find(step => {
    return step.urlMatch.test(url) && (!step.urlNotMatch || !step.urlNotMatch.test(url));
  });
  const {
    isOpen,
    setIsOpen,
    setSteps
  } = useTour();
  const isOnboarded = !!(!stepMatcher || settings.onboarded && settings.onboarded[stepMatcher.settingName]);
  useEffect(() => {
    if (stepMatcher && setSteps) {
      setSteps(stepMatcher.steps);
    }
    if (!isOpen && !isOnboarded) {
      setIsOpen(true);
      // Wish there was a better way to do this...
      chatdb.updateUserOnboarded(stepMatcher.settingName, true).then(newSettings => {
        updateSettings(newSettings);
        console.info(`Set onboarded.${stepMatcher.settingName} = true`);
      }).catch(e => {
        console.error("Error updating onboarded setting", e);
      });
    }
  }, [stepMatcher, setSteps, url, updateSettings, isOnboarded]);
  return {
    isOpen,
    setIsOpen
  };
}
export function OnboardButton({
  className
}: {
  className?: string;
}) {
  const pathname = usePathname();
  const query = useSearchParams();
  const url = `${pathname}${query.toString() ? `?${query}` : ""}${hashParamsSignal.toString() ? `#${hashParamsSignal.toString()}` : ""}`;
  const stepMatcher = steps.find(step => {
    return step.urlMatch.test(url) && (!step.urlNotMatch || !step.urlNotMatch.test(url));
  });
  const {
    isOpen,
    setIsOpen
  } = useTour();
  if (!stepMatcher) {
    return null;
  }
  return <button onClick={() => setIsOpen(!isOpen)} className={twMerge("rounded-full", className)} data-sentry-component="OnboardButton" data-sentry-source-file="onboarding.tsx">
      <QuestionMarkCircleIcon className="h-6 w-6" data-sentry-element="QuestionMarkCircleIcon" data-sentry-source-file="onboarding.tsx" />
    </button>;
}