export function formatDateTime(
  date: Date | number,
  timezone: string = localTimezone()
): string {
  date = typeof date === "number" ? new Date(date) : date;
  const formatter = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: timezone,
    timeZoneName: "short",
  });

  const parts = formatter.formatToParts(date);

  const getPart = (type: string) =>
    parts.find((p) => p.type === type)?.value || "";

  return (
    `${getPart("weekday")}, ` +
    `${getPart("year")}-${getPart("month")}-${getPart("day")} ` +
    `${getPart("hour")}:${getPart("minute")}${getPart("dayPeriod")} ` +
    `${getPart("timeZoneName")}`
  );
}

export function formatDate(
  date: Date | number,
  timezone: string = localTimezone()
) {
  const formatter = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: timezone,
    timeZoneName: "short",
  });
  date = typeof date === "number" ? new Date(date) : date;
  const parts = formatter.formatToParts(date);
  const getPart = (type: string) =>
    parts.find((p) => p.type === type)?.value || "";

  return `${getPart("year")}-${getPart("month")}-${getPart("day")}`;
}

export function localTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
